import { useState, useEffect } from 'react';
import EndPoint from '../utils/endpoint';
import { secureAxios } from '../utils/secureAxios';

export const useChannelList = () => {
  const [isCancelled, setIsCancelled] = useState(false);
  const [error, setError] = useState(null);
  const [isPending, setIsPending] = useState(false);

  const fetchChannelList = async (email = null) => {
    setError(null);
    setIsPending(true);

    try {
      const res = await secureAxios.get(EndPoint.channelList());
      const { code, channels } = res.data;
      if (code === 200) {
        if (!isCancelled) {
          setIsPending(false);
          setError(null);
        }
        const result = channels
          .filter((it) => it.channel_name && it.yt_channel_id && it.email_list)
          .map((it) => {
            return {
              channelId: it.yt_channel_id,
              emailList: it.email_list.split(',').map((it) => it.trim()),
              channelUrl: `https://www.youtube.com${it.yt_channel_id}`,
              channelName: it.channel_name,
              isWithdrawn: it.is_withdrawn,
            };
          });
        return email ? result.filter((it) => it.emailList.includes(email)) : result;
      } else {
        throw new Error(`${code} :: unknown error occurred`);
      }
    } catch (err) {
      if (!isCancelled) {
        setError(err.message);
        setIsPending(false);
      }
    }
  };

  useEffect(() => {
    return () => setIsCancelled(true);
  }, []);

  return { fetchChannelList, isPending, error };
};
