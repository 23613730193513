import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
export const Alert = withReactContent(Swal);
export const toast = async ({ message, width, height, duration = 3000 }) => {
  await Alert.fire({
    html: `<p>${message}</p>`,
    width,
    height,
    padding: '10px',
    timer: duration,
    showConfirmButton: false,
    background: 'black',
    color: 'white',
  });
};
