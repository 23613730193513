const CURRENCY_LOCALE_CODE_MAP = {
  krw: ['ko-KR', 'KRW'],
  twd: ['zh-Hans', 'TWD'],
  myr: ['en-MY', 'MYR'],
};

export const formatCurrency = (currency, n) => {
  const [locale, currencyCode] = CURRENCY_LOCALE_CODE_MAP[currency] ?? CURRENCY_LOCALE_CODE_MAP.krw;
  return new Intl.NumberFormat(locale, { style: 'currency', currency: currencyCode }).format(n);
};

export const numberWithCommas = (n) => {
  return parseInt(n) // to remove float point                                                                                                                           bb
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};
