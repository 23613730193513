import classnames from 'classnames';

const ArrowDown = ({ className }) => (
  <svg
    className={classnames('w-4 h-4', className)}
    fill="none"
    stroke="currentColor"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg">
    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 14l-7 7m0 0l-7-7m7 7V3"></path>
  </svg>
);

export default ArrowDown;
