import { useState, useEffect } from 'react';
import { secureAxios } from '../utils/secureAxios';
import EndPoint from '../utils/endpoint';

export const useChannelPostList = (ytChannelId, paramPage, paramPerPage = 50) => {
  const [isPending, setIsPending] = useState(false);
  const [error, setError] = useState(null);
  const [posts, setPosts] = useState([]);
  const [sortOption, setSortOption] = useState({ sortBy: 'pv_total', sortOrder: 'DESC' });
  const [page, setPage] = useState(paramPage);
  const [totalPage, setTotalPage] = useState(0);

  useEffect(() => {
    const fetchChannelPostList = async (ytChannelId, page, perPage) => {
      setIsPending(true);
      setError(null);
      try {
        const result = await secureAxios.get(EndPoint.channelPostList(), {
          params: {
            channel_id: ytChannelId,
            page,
            per_page: perPage,
            order_by: sortOption.sortBy,
            order: sortOption.sortOrder,
          },
        });
        const { post_list: posts, total_page: totalPage } = result.data;
        setPosts(posts);
        setTotalPage(totalPage === 0 ? 1 : totalPage);
        setIsPending(false);
      } catch (error) {
        setIsPending(false);
        setError(error.message);
      }
    };

    try {
      fetchChannelPostList(ytChannelId, page, paramPerPage);
    } catch (error) {
      setIsPending(false);
      setError(error.message);
    }
  }, [page, ytChannelId, paramPerPage, sortOption]);

  return { isPending, error, sortOption, setSortOption, page, setPage, totalPage, posts };
};
