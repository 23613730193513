// @see https://github.com/facebook/create-react-app/issues/11756
// we could not use dotenv for above issue. ㅜㅜ

import firebase from 'firebase/app';
import 'firebase/analytics';
import 'firebase/firestore';
import 'firebase/auth';
import 'firebase/storage';

const firebaseConfig = {
  // apiKey: 'AIzaSyDfJCRZ1hQCDaOO8BvlWBzuWqgAq_O85gk',
  apiKey: 'AIzaSyAKZKGq_sVDgF4ZzP_VcHuOddMPLUHOsR0',
  authDomain: 'admin.youtext.ai',
  projectId: 'youtext-dashboard',
  storageBucket: 'youtext-dashboard.appspot.com',
  messagingSenderId: '680342315251',
  appId: '1:680342315251:web:50e75364b97347f4629786',
  measurementId: 'G-64F6Z66W11',
};

// init firebase
firebase.initializeApp(firebaseConfig);

// init service
const projectAnalytics = firebase.analytics();
const projectFirestore = firebase.firestore();
const projectAuth = firebase.auth();
const projectStorage = firebase.storage();
const projectGoogleAuthProvider = new firebase.auth.GoogleAuthProvider();
projectGoogleAuthProvider.setCustomParameters({ prompt: 'select_account' });

// timestamp
// we need it to create timestamp in firebase date format.
const timestamp = firebase.firestore.Timestamp;
const userAuthToken = async () => {
  try {
    const token = await projectAuth.currentUser.getIdToken();
    return token;
  } catch (error) {
    alert(error.message);
    throw error;
  }
};

export {
  projectAnalytics,
  projectFirestore,
  projectAuth,
  projectGoogleAuthProvider,
  projectStorage,
  timestamp,
  userAuthToken,
};
