import React from 'react';
import { useAuthContext } from '../hooks/useAuthContext';

export default function Avatar() {
  const { user } = useAuthContext();
  return (
    <div className="avatar">
      <div className="rounded-full w-7 h-7 m-1">
        <img src={user.photoURL} alt="avatar" />
      </div>
    </div>
  );
}
