import React from 'react';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { DateRangePicker as DateRangePickerComponent } from 'react-date-range';
import { ko, zhTW } from 'date-fns/locale';
import * as dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import './DateRangePicker.css';
import { useLanguage } from '../hooks/useLanguage';

export default function DateRangePicker({ dateRange, minDate, maxDate, handleSelectDateRange }) {
  const { t } = useTranslation();
  const { isZHTW } = useLanguage();
  const startDate = dayjs(dateRange.startDate);
  const endDate = dayjs(dateRange.endDate);
  const diff = 1 + endDate.diff(startDate, 'day');
  const startDateString = startDate.format(t('YYYY년 MM월 DD일')).toString();
  const endDateString = endDate.format(t('YYYY년 MM월 DD일')).toString();
  const displayDateString = t('$기간선택 표시 문구', {
    days: diff,
    startDateString,
    endDateString,
  });

  return (
    <div
      className="dropdown dropdown-hover dropdown-end"
      style={{ textAlign: 'right', minWidth: '320px' }}>
      {displayDateString !== '$기간선택 표시 문구' ? (
        <div tabIndex="0" className="btn btn-ghost border-1 border-gray-800">
          {displayDateString}
        </div>
      ) : (
        <div
          tabIndex="0"
          className="btn btn-ghost loading border-2 border-primary text-primary pl-6"></div>
      )}
      <div tabIndex="0" className="p-2 shadow menu dropdown-content bg-base-100 rounded-box w-max">
        <DateRangePickerComponent
          locale={isZHTW ? zhTW : ko}
          ranges={[dateRange]}
          minDate={minDate}
          maxDate={maxDate}
          onChange={handleSelectDateRange}
          scroll={{ enabled: true }}
          showDateDisplay={false}
        />
      </div>
    </div>
  );
}
