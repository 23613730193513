import dayjs from 'dayjs';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as QMark } from '../assets/q.svg';
import { formatCurrency } from '../utils/formatNumber';

const promotionDesc = (t, promotionItem) => {
  const startDay = dayjs(promotionItem.mg_promo_start_date).format('YY.MM.DD');
  const endDay = dayjs(promotionItem.mg_promo_end_date).format('YY.MM.DD');
  const revenue = formatCurrency(promotionItem.currency, promotionItem.mg_promo_monthly_amount ?? 0);
  return t('$프로모션 설명', { revenue, startDay, endDay });
};

function PromotionWithTooltip({ promotionItem }) {
  const { t } = useTranslation();
  return (
    <div className="w-full">
      <div className="dropdown dropdown-hover dropdown-right">
        <div tabIndex="0" className="w-full">
          <div className="flex justify-end items-center gap-5">
            {t('$가격', {
              price: formatCurrency(promotionItem.currency, promotionItem.mg_promo_revenue),
            })}
            <QMark style={{ width: '18px', height: '18px' }} />
          </div>
        </div>
        <div
          tabIndex="0"
          className="dropdown-content p-2 ml-1 shadow-md rounded-md bg-secondary text-white font-bold"
          style={{ fontSize: '15px', position: 'absolute', top: '-20px' }}
        >
          <div
            className="text-left"
            dangerouslySetInnerHTML={{
              __html: promotionDesc(t, promotionItem),
            }}
          ></div>
        </div>
      </div>
    </div>
  );
}

function PromotionWithoutTooltip({ promotionItem }) {
  const { t } = useTranslation();
  return (
    <div className="flex justify-end items-center gap-5">
      {t('$가격', {
        price: formatCurrency(promotionItem.currency, promotionItem.mg_promo_revenue),
      })}
      <QMark style={{ width: '18px', height: '18px', visibility: 'hidden' }} />
    </div>
  );
}

export default function PromotionCell({ promotionItem }) {
  return (
    <>
      {promotionItem.mg_promo_revenue > 0 && promotionItem.mg_promo_start_date && promotionItem.mg_promo_end_date ? (
        <PromotionWithTooltip promotionItem={promotionItem} />
      ) : (
        <PromotionWithoutTooltip promotionItem={promotionItem} />
      )}
    </>
  );
}
