import React from 'react';
import { useTranslation } from 'react-i18next';

export default function LoginPageTitle() {
  const { t } = useTranslation();
  return (
    <div className="flex flex-col gap-5 sm:mb-7">
      <p className="font-bold text-white text-center text-3xl md:text-8xl">
        YouText
      </p>
      <p className="font-bold text-gray-200 text-center text-sm md:text-md sm:text-left sm:ml-6">
        {t('글로 읽는 동영상!')}
        <br />
        {t('내가 만든 유튜브 동영상으로 새로운 수익을 창출해 보세요!')}
      </p>
    </div>
  );
}
