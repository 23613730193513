import dayjs from 'dayjs';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { numberWithCommas } from '../utils/formatNumber';
import { makeYouTextShareUrl, makeYouTextShareShortUrl } from '../utils/youtextShortUrl';
import Spinner from './icons/Spinner';
import ArrowDown from './icons/ArrowDown';
import ArrowUp from './icons/ArrowUp';
import ChannelPostLink from './ChannelPostLink';
import './ChannelPostListTable.css';

export default function ChannelPostListTable({ isPending, posts, sortOption, setSortOption }) {
  const { sortBy, sortOrder } = sortOption;
  const { t } = useTranslation();

  const toggleSortOption = useCallback(
    (nextSortBy) => {
      const isSameSortBy = nextSortBy === sortBy;
      if (isSameSortBy) {
        const nextSortOrder = sortOrder === 'DESC' ? 'ASC' : 'DESC';
        setSortOption({ ...sortOption, sortOrder: nextSortOrder });
      } else {
        setSortOption({ sortBy: nextSortBy, sortOrder: 'DESC' });
      }
    },
    [sortOption, sortBy, sortOrder, setSortOption]
  );

  const sortOrderArrow =
    sortOrder === 'DESC' ? (
      <ArrowDown className="mr-1 inline" />
    ) : (
      <ArrowUp className="mr-1 inline" />
    );
  const arrowForDate = sortBy === 'date' ? sortOrderArrow : null;
  const arrowForPvTotal = sortBy === 'pv_total' ? sortOrderArrow : null;

  return (
    <table className="channel-post-list-table">
      <colgroup>
        <col style={{ width: '40%' }} />
        <col style={{ width: '38%' }} />
        <col style={{ width: '12%' }} />
        <col style={{ width: '10%' }} />
      </colgroup>
      <thead>
        <tr>
          <th className="channel-post-column-title">{t('콘텐츠')}</th>
          <th className="channel-post-column-title">{t('URL')}</th>
          <th
            className="channel-post-column-title select-none cursor-pointer"
            onClick={() => toggleSortOption('date')}>
            {arrowForDate}
            {t('등록일')}
          </th>
          <th
            className="channel-post-column-title select-none cursor-pointer"
            onClick={() => toggleSortOption('pv_total')}>
            {arrowForPvTotal}
            {t('페이지뷰')}
          </th>
        </tr>
      </thead>
      <tbody>
        {isPending && (
          <tr>
            <td colSpan={4}>
              <div className="p-40 flex justify-center">
                <Spinner />
              </div>
            </td>
          </tr>
        )}
        {!isPending && posts.length === 0 && (
          <tr>
            <td colSpan={4} className="text-center">
              {t('게시한 글이 없습니다.')}
            </td>
          </tr>
        )}
        {!isPending &&
          posts.map((it) => {
            const shareUrl = makeYouTextShareUrl(it.country, it.slug);
            const shareShortUrl = makeYouTextShareShortUrl(it.country, it.slug);
            return (
              <tr key={it.slug}>
                <td className="flex items-center gap-8">
                  <div className="channel-post-thumbnail">
                    <img src={it.thumb_url} alt="thumbnail" />
                  </div>
                  <div className="channel-post-title whitespace-pre-wrap">
                    <a
                      href={shareShortUrl}
                      target="_blank"
                      rel="noreferrer"
                      dangerouslySetInnerHTML={{ __html: it.title }}></a>
                  </div>
                </td>
                <td>
                  <ChannelPostLink
                    title={it.title}
                    thumbUrl={it.thumb_url}
                    originalUrl={shareUrl}
                    shortUrl={shareShortUrl}
                  />
                </td>
                <td className="channel-post-date text-center">
                  {dayjs(it.date).format('YYYY-MM-DD')}
                </td>
                <td className="text-center">{numberWithCommas(it.pv_total)}</td>
              </tr>
            );
          })}
      </tbody>
    </table>
  );
}
