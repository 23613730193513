import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useChannelPostList } from '../hooks/useChannelPostList';
import { useChannelPostStats } from '../hooks/useChannelPostStats';
import { numberWithCommas } from '../utils/formatNumber';
import ChannelPostListPager from './ChannelPostListPager';
import ChannelPostListTable from './ChannelPostListTable';
import Stat from './Stat';
import './ChannelPostListContainer.css';

export default function ChannelPostListContainer({ ytChannelId }) {
  const { t } = useTranslation();
  const { isPending, sortOption, setSortOption, page, setPage, totalPage, posts } = useChannelPostList(ytChannelId, 1);
  const { isPending: isPostStatsPending, totalPostCount, totalPageView } = useChannelPostStats(ytChannelId);

  useEffect(() => {
    setPage(1);
  }, [ytChannelId, setPage]);

  return (
    <div className="flex flex-col">
      <div className="flex flex-row gap-10 mb-10">
        <Stat title={t('누적 콘텐츠')} value={numberWithCommas(totalPostCount)} isLoading={isPostStatsPending} />
        <Stat title={t('누적 페이지뷰')} value={numberWithCommas(totalPageView)} isLoading={isPostStatsPending} />
        <div className="w-full" />
      </div>
      <ChannelPostListTable isPending={isPending} posts={posts} sortOption={sortOption} setSortOption={setSortOption} />
      <hr className="channel-post-hr mb-4" />
      {!isPending && <ChannelPostListPager page={page} setPage={setPage} totalPage={totalPage} />}
    </div>
  );
}
