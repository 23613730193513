import React from 'react';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from '../hooks/useAuthContext';
import { useLogout } from '../hooks/useLogout';
import Avatar from './Avatar';
import Languages from './Languages';

export default function Navbar() {
  const { t } = useTranslation();
  const { logout } = useLogout();
  const { user } = useAuthContext();

  const handleClickBizCert = () => {
    window.open('about:blank').location.href =
      'https://drive.google.com/file/d/16F_qEQyR8YdFhckAMCVnJYmTkbnkQEOh/view?usp=sharing';
  };

  const handleClickLogout = () => {
    logout();
  };

  return (
    <div className="flex items-center py-2 px-5 shadow-md">
      <div className="pr-3 mr-auto flex-shrink-0">
        <img src="/youtext-logo.png" width={100} alt="youtext" />
      </div>
      {user && (
        <>
          <Languages />
          <div
            className="btn btn-ghost mx-3 flex-shrink-0"
            style={{ minWidth: 200 }}
            onClick={handleClickBizCert}>
            {t('데이블 사업자 등록증')}
          </div>
          <div className="dropdown dropdown-end dropdown-hover">
            <div tabIndex="0" className="cursor-pointer hover:bg-gray-200 rounded-md">
              <Avatar />
            </div>
            <ul
              tabIndex="0"
              className="p-2 shadow menu dropdown-content bg-base-100 rounded-md w-32">
              <li>
                <button className="btn btn-ghost btn-sm rounded-md" onClick={handleClickLogout}>
                  {t('로그아웃')}
                </button>
              </li>
            </ul>
          </div>
        </>
      )}
    </div>
  );
}
