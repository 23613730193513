import { useState, useEffect } from 'react';
import { projectAuth, projectGoogleAuthProvider, projectFirestore } from '../firebase';
import { useAuthContext } from './useAuthContext';
import { loadAdminListSheet } from '../utils/googleSheet';
import { loadYoutubeChannelName } from '../utils/youtubeChannel';
import { useTranslation } from 'react-i18next';
import { useChannelList } from './useChannelList';

export const useGoogleLogin = () => {
  const [isCancelled, setIsCancelled] = useState(false);
  const [error, setError] = useState(null);
  const [isPending, setIsPending] = useState(false);
  const { dispatch } = useAuthContext();
  const { i18n } = useTranslation();
  const { fetchChannelList } = useChannelList();

  const login = async () => {
    setError(null);
    setIsPending(true);

    try {
      // set auth popup language
      projectAuth.languageCode = i18n.language;
      const res = await projectAuth.signInWithPopup(projectGoogleAuthProvider);
      if (!res) {
        throw new Error('Could not complete login');
      }
      const { email } = res.user;
      const adminSheetData = (await loadAdminListSheet()).filter((it) => it.emailList.includes(email));
      const userSheetData = (await fetchChannelList()).filter((it) => it.emailList.includes(email));

      // 어드민 포함 탈퇴한 회원은 로그인할 수 없다.
      // eslint-disable-next-line no-unreachable
      const isWithdrawnUser =
        (adminSheetData.length > 0 && adminSheetData[0].isWithdrawn) ||
        (userSheetData.length > 0 && userSheetData[0].isWithdrawn);

      if (isWithdrawnUser) {
        await projectAuth.signOut();
        throw new Error(`Only user who is registered can login.`);
      }

      let isAdmin = false;
      let hasMultiChannels = false;
      if (adminSheetData.length > 0) {
        isAdmin = true;
      } else if (userSheetData.length > 1) {
        hasMultiChannels = true;
      } else if (userSheetData.length === 0) {
        // 없거나 두 개이상 중복되면 안된다.
        await projectAuth.signOut();
        throw new Error(`Only user who is registered can login.`);
      }

      // check if user exists in users collection.
      let channelUrl = '';
      let channelName = '';
      let channelId = '';
      // create a user document
      if (isAdmin) {
        channelId = '/channel/UCFvUI44ugAv8-cg6A6MvBEg';
        channelUrl = 'https://www.youtube.com/channel/UCFvUI44ugAv8-cg6A6MvBEg';
        channelName = '데이블';
        await projectFirestore.collection('users').doc(res.user.uid).set({
          online: true,
          displayName: res.user.displayName,
          photoURL: res.user.photoURL,
          channelId,
          channelUrl,
          channelName,
          isAdmin,
          hasMultiChannels,
        });
      } else {
        const userAuthData = userSheetData[0];
        channelId = userAuthData.channelId;
        channelUrl = userAuthData.channelUrl;
        channelName = await loadYoutubeChannelName(userAuthData.channelUrl);
        await projectFirestore.collection('users').doc(res.user.uid).set({
          online: true,
          displayName: res.user.displayName,
          photoURL: res.user.photoURL,
          channelId,
          channelUrl,
          channelName,
          isAdmin,
          hasMultiChannels,
        });
      }

      // dispatch login action
      dispatch({
        type: 'LOGIN',
        payload: {
          ...res.user,
          channelUrl,
          channelName,
          isAdmin,
          hasMultiChannels,
        },
      });

      if (!isCancelled) {
        setIsPending(false);
        setError(null);
      }
    } catch (err) {
      await projectAuth.signOut();
      if (!isCancelled) {
        setError(err.message);
        setIsPending(false);
      }
    }
  };

  // cancel login if get off the login page.
  useEffect(() => {
    return () => setIsCancelled(true);
  }, []);

  return { login, isPending, error };
};
