import React from 'react';
import { useLanguage } from '../../hooks/useLanguage';
import LoginForm from './components/LoginForm';
import SiteMapKo from './components/SiteMapKo';
import SiteMap from './components/SiteMap';
import './Login.css';

export default function Login() {
  const { isKorean } = useLanguage();
  return (
    <div className="login-page">
      <div className="login-form-container">
        <LoginForm />
      </div>
      <div className="bottom-container">{isKorean ? <SiteMapKo /> : <SiteMap />}</div>
    </div>
  );
}
