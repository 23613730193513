import { useState, useEffect } from 'react';
import EndPoint from '../utils/endpoint';
import { secureAxios } from '../utils/secureAxios';

async function totalPostByChannel(ytChannelId) {
  const { code, total_post_by_channel } = (
    await secureAxios.get(EndPoint.totalPostByChannel(), { params: { yt_channel_id: ytChannelId } })
  ).data;
  return code === 200 ? total_post_by_channel : 0;
}

async function totalPvByChannel(ytChannelId) {
  const { code, total_pv_by_channel } = (
    await secureAxios.get(EndPoint.totalPvByChannel(), { params: { yt_channel_id: ytChannelId } })
  ).data;
  return code === 200 ? total_pv_by_channel : 0;
}

export const useChannelPostStats = (ytChannelId) => {
  const [error, setError] = useState(null);
  const [stats, setStats] = useState({ totalPostCount: 0, totalPageView: 0 });
  const [isPending, setIsPending] = useState(false);

  useEffect(() => {
    async function fetchStats() {
      setError(null);
      setIsPending(true);
      try {
        const totalPostCount = await totalPostByChannel(ytChannelId);
        const totalPageView = await totalPvByChannel(ytChannelId);
        setStats({ totalPostCount, totalPageView });
        setIsPending(false);
      } catch (err) {
        setError(err.message);
        setIsPending(false);
      }
    }
    fetchStats();
  }, [ytChannelId]);
  return { ...stats, isPending, error };
};
