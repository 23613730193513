import React from 'react';
import { useTranslation } from 'react-i18next';

export default function SiteMap() {
  const { t } = useTranslation();
  return (
    <div className="flex sm:justify-center">
      <div className="py-10 px-10 sm:px-48 flex flex-col">
        <div className="flex flex-col sm:flex-row gap-8 text-sm text-gray-500 mb-8">
          <a href="mailto:youtext@dable.io" target="_top">
            {t('고객센터')}
          </a>
        </div>
        <div className="text-xs w-full text-left text-gray-500">YouText &copy; 2022</div>
      </div>
    </div>
  );
}
