// 개발서버는 http만 지원한다.
const server = {
  production: {
    creation: 'https://api.youtext.com:6904/api/v1/download_vid/',
    posting: 'https://api.youtext.com:6905/api/v1/post/wordpress/',
    categoryList: 'https://api.youtext.com:6905/api/v1/post/wordpress/category/',
    revenue: 'https://api.youtext.com:6908/api/v1/db/channel_revenue',
    unsubscribe: 'https://us-central1-rosetta-stone-329408.cloudfunctions.net/api/unsubscribe/email',
    channelPostList: 'https://api.youtext.com:6908/api/v1/db/post_info/',
    channelList: 'https://api.youtext.com:6908/api/v1/db/channels',
    totalPostByChannel: 'https://api.youtext.com:6908/api/v1/db/total_post_by_channel',
    totalPvByChannel: 'https://api.youtext.com:6908/api/v1/db/total_pv_by_channel',
  },
  development: {
    creation: 'https://api-dev.youtext.com:6904/api/v1/download_vid/',
    posting: 'https://api-dev.youtext.com:6905/api/v1/post/wordpress/',
    categoryList: 'https://api-dev.youtext.com:6905/api/v1/post/wordpress/category/',
    revenue: 'https://api-dev.youtext.com:6908/api/v1/db/channel_revenue',
    unsubscribe: 'https://us-central1-rosetta-stone-329408.cloudfunctions.net/api/unsubscribe/email',
    channelPostList: 'https://api-dev.youtext.com:6908/api/v1/db/post_info/',
    channelList: 'https://api-dev.youtext.com:6908/api/v1/db/channels',
    totalPostByChannel: 'https://api-dev.youtext.com:6908/api/v1/db/total_post_by_channel',
    totalPvByChannel: 'https://api-dev.youtext.com:6908/api/v1/db/total_pv_by_channel',
  },
  local: {
    revenue: 'http://127.0.0.1:5908/api/v1/db/channel_revenue',
    channelPostList: 'http://127.0.0.1:5908/api/v1/db/post_info/',
    channelList: 'http://127.0.0.1:5908/api/v1/db/channels',
    totalPostByChannel: 'http://127.0.0.1:5908/api/v1/db/total_post_by_channel',
    totalPvByChannel: 'http://127.0.0.1:5908/api/v1/db/total_pv_by_channel',
  },
};

let serverEnv = (process && process.env.REACT_APP_API_SERVER) || 'prod';

if (window && window.location && window.location.search) {
  const searchParams = new URLSearchParams(window.location.search);
  const userServerEnv = searchParams.get('__server_env');
  if (userServerEnv) {
    serverEnv = userServerEnv;
  }
}
if (serverEnv !== 'prod') {
  console.info(`serverEnv: ${serverEnv}`);
}

const revenue = () => {
  if (serverEnv === 'dev') {
    return server.development.revenue;
  } else if (serverEnv === 'local') {
    return server.local.revenue;
  }
  return server.production.revenue;
};

const unsubscribe = () => {
  return server.production.unsubscribe;
};

const channelPostList = () => {
  if (serverEnv === 'dev') {
    return server.development.channelPostList;
  } else if (serverEnv === 'local') {
    return server.local.channelPostList;
  }
  return server.production.channelPostList;
};

const channelList = () => {
  if (serverEnv === 'dev') {
    return server.development.channelList;
  } else if (serverEnv === 'local') {
    return server.local.channelList;
  }
  return server.production.channelList;
};

const totalPostByChannel = () => {
  if (serverEnv === 'dev') {
    return server.development.totalPostByChannel;
  } else if (serverEnv === 'local') {
    return server.local.totalPostByChannel;
  }
  return server.production.totalPostByChannel;
};

const totalPvByChannel = () => {
  if (serverEnv === 'dev') {
    return server.development.totalPvByChannel;
  } else if (serverEnv === 'local') {
    return server.local.totalPvByChannel;
  }
  return server.production.totalPvByChannel;
};

const EndPoint = {
  revenue,
  unsubscribe,
  channelPostList,
  channelList,
  totalPostByChannel,
  totalPvByChannel,
};

export default EndPoint;
