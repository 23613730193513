import React from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from './Alert';
import { shareFacebook, shareKakao, shareNaver, shareTwitter } from '../utils/share';
import './ChannelPostLink.css';

export default function ChannelPostLink({ title, thumbUrl, originalUrl, shortUrl }) {
  const { t } = useTranslation();

  const handleCopyUrl = async (url) => {
    try {
      await navigator.clipboard.writeText(url);
      await toast({ message: t('URL 복사 완료'), width: 204, height: 50 });
    } catch (error) {
      await toast({
        message: t('URL을 복사할 수 없습니다.'),
        width: 204,
        height: 50,
      });
    }
  };
  const handleShareFacebook = () => shareFacebook(shortUrl);
  const handleShareNaver = () => shareNaver(title, shortUrl);
  const handleShareTwitter = () => shareTwitter(title, shortUrl);
  // 카카오톡 공유하기에는 shortUrl이 동작하지 않아 originalUrl을 사용합니다.
  const handleShareKakao = () => shareKakao(title, originalUrl, thumbUrl);

  return (
    <div className="flex justify-end items-center gap-2">
      <span className="channel-post-link" onClick={() => handleCopyUrl(shortUrl)}>
        {shortUrl}
      </span>
      <button className="channel-post-link-copy-btn" onClick={() => handleCopyUrl(shortUrl)}>
        {t('복사')}
      </button>
      <img
        className="channel-post-link-share-btn"
        alt="share"
        src="/share_icons/facebook.png"
        onClick={handleShareFacebook}
      />
      <img
        className="channel-post-link-share-btn"
        alt="share"
        src="/share_icons/naver.png"
        onClick={handleShareNaver}
      />
      <img
        className="channel-post-link-share-btn"
        alt="share"
        src="/share_icons/twitter.png"
        onClick={handleShareTwitter}
      />
      <img
        className="channel-post-link-share-btn"
        alt="share"
        src="/share_icons/kakaotalk.png"
        onClick={handleShareKakao}
      />
    </div>
  );
}
