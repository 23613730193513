import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from '../hooks/useAuthContext';
import { useRevenue } from '../hooks/useRevenue';
import ErrorAlert from './ErrorAlert';
import PromotionStat from './PromotionStat';
import RevenueTable from './RevenueTable';
import Stat from './Stat';

export default function RevenueContainer({ ytChannelId, dateRange }) {
  const { t } = useTranslation();
  const { user } = useAuthContext();
  const { fetchRevenue, isPending, error, pageView, revenue, expectAdRevenue, hasPromotion, promotion, rpm, history } =
    useRevenue();

  useEffect(() => {
    if (!user) {
      return;
    }
    if (!ytChannelId) {
      return;
    }
    fetchRevenue(ytChannelId, dateRange.startDate, dateRange.endDate);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, ytChannelId, dateRange]);

  return (
    <div>
      <div className="flex flex-row gap-10 mb-10 items-center justify-evenly">
        <PromotionStat
          totalRevenueTitle={t('예상 수입 합계')}
          totalRevenueValue={t('$가격', { price: revenue })}
          expectAdRevenueTitle={t('예상 광고 수입')}
          expectAdRevenueValue={t('$가격', { price: expectAdRevenue })}
          hasPromotion={hasPromotion}
          promotionTitle={t('프로모션 수입')}
          promotionValue={t('$가격', { price: promotion })}
          descriptions={[
            {
              title: t('예상 수입 합계'),
              body: t('프로모션 수입과 예상 광고 수입의 합계입니다.'),
              show: hasPromotion,
            },
            {
              title: t('프로모션 수입'),
              body: t('광고 수입 외 기간 한정 프로모션 수입입니다.'),
              show: hasPromotion,
            },
            {
              title: t('예상 광고 수입'),
              body: t(
                '선택한 기간에 발생한 총 추정 수입(순수입)입니다. 확정 수입은 월말 조정을 거쳐 월초에 확정 됩니다.'
              ),
              show: true,
            },
          ]}
        />
        <Stat
          title={t('페이지뷰')}
          value={pageView}
          description={{
            title: t('페이지뷰'),
            body: t('선택한 기간에 발생한 총 페이지뷰입니다.'),
          }}
          isLoading={isPending}
        />
        <Stat
          title={'RPM'}
          value={t('$가격', { price: rpm })}
          description={{
            title: 'RPM',
            body: t(
              'RPM은 1,000회당 광고 수입을 의미하며 조회수 1,000회 마다 발생한 광고 수입입니다. 예상 광고 수입을 동일 한 기간의 총 페이지뷰로 나눕니다.'
            ),
          }}
          isLoading={isPending}
        />
      </div>
      {error && <ErrorAlert error={error} />}
      <RevenueTable history={history} hasPromotion={hasPromotion} />
    </div>
  );
}
