import { useState, useEffect } from 'react';
import * as EmailValidator from 'email-validator';
import { secureAxios } from '../utils/secureAxios';
import EndPoint from '../utils/endpoint';

export const useUnsubscribeEmail = (email) => {
  const [isValidEmail, setIsValidEmail] = useState();
  const [isPending, setIsPending] = useState(false);
  const [resultEmail, setResultEmail] = useState();

  useEffect(() => {
    const unsubscribe = async (email) => {
      try {
        setIsPending(true);
        const result = await secureAxios.post(EndPoint.unsubscribe(), {
          email,
        });
        const { data } = result.data;
        setIsPending(false);
        setResultEmail(data.email);
      } catch (error) {
        setIsPending(false);
      }
    };
    if (!email || !EmailValidator.validate(email)) {
      setIsValidEmail(false);
      return;
    }
    setIsValidEmail(true);
    unsubscribe(email);
  }, [email]);

  return { isPending, isValidEmail, resultEmail };
};
