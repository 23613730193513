import axios from 'axios';

export const loadYoutubeChannelName = async (channelUrl) => {
  const url = new URL(channelUrl);
  const paths = url.pathname.split('/').filter((it) => it.trim().length > 0);
  if (paths.length === 0) {
    throw new Error('invalid youtube channel url');
  }
  const channelId = paths[paths.length - 1];
  const result = await axios.get(
    `https://www.googleapis.com/youtube/v3/channels?id=${channelId}&part=snippet&key=AIzaSyAxozsnZdChOcojR8rXTs5s6sJ3svTbZ4E`
  );
  if (result.data.totalResults <= 0) {
    throw new Error('invalid youtube channel id');
  }
  return result.data.items[0].snippet.title;
};
