import React, { useState, useEffect, useRef } from 'react';
import WAVES from 'vanta/dist/vanta.waves.min';
import LoginPageTitle from './LoginPageTitle';
import Languages from '../../../components/Languages';
import { useGoogleLogin } from '../../../hooks/useGoogleLogin';
import { useTranslation } from 'react-i18next';
import { useLanguage } from '../../../hooks/useLanguage';

export default function LoginForm() {
  const { t } = useTranslation();
  const { login, isPending, error } = useGoogleLogin();
  const [vantaEffect, setVantaEffect] = useState(0);
  const vantaRef = useRef(null);
  const { isKorean } = useLanguage();

  useEffect(() => {
    if (!vantaEffect) {
      setVantaEffect(
        WAVES({
          el: vantaRef.current,
        })
      );
    }
    return () => {
      if (vantaEffect) vantaEffect.destroy();
    };
  }, [vantaEffect]);

  const handleSignupClick = () => {
    window.location.href = 'https://youtext.ai/partnership';
  };

  return (
    <div className="h-full" ref={vantaRef}>
      <div className="flex flex-col justify-center items-center h-full gap-8 sm:flex-row">
        <div className="flex flex-col items-center">
          <LoginPageTitle />
          <Languages />
        </div>
        <div className="card bg-white shadow-lg w-3/5 mx-10 sm:w-1/3 max-w-md">
          <div className="card-body gap-3">
            <h2 className="card-title text-center">{t('지금 수익을 확인하세요!')}</h2>
            {!error && isPending && (
              <button className="btn btn-secondary loading gap-2 p-2 sm:text-xl" disabled>
                {t('로그인중')}
              </button>
            )}
            {!error && !isPending && (
              <button className="btn btn-secondary gap-2 p-2 sm:text-xl" onClick={login}>
                {t('구글 계정으로 로그인')}
              </button>
            )}
            {error && !isPending && (
              <span className="text-sm">
                {t('로그인 할 수 없습니다. 계정이 없는 경우 가입 심사 신청을 해 주세요.')}
              </span>
            )}
            {/* LoginFormZHTW로 따로 분리하지 않은 이유는 isZHTW이 결정되기 전까지 vanta 3d effect 가 여러번 실행되어 FPS가 낮아져서 여기서 조건 분기함. */}
            {isKorean ? (
              <>
                <div className="h-px bg-gray-300 sm:my-3"></div>
                <div className="card-actions justify-center">
                  <button className="btn btn-primary" onClick={handleSignupClick}>
                    {t('가입 심사 신청')}
                  </button>
                </div>
              </>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
}
