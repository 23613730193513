function openPopup(url, width, height) {
  const left = (window.screen.availWidth - width) * 0.5;
  const top = (window.screen.availHeight - height) * 0.5;
  window.open(url, '_blank', `width=${width},height=${height},left=${left},top=${top}`);
}

export function shareNaver(title, url) {
  openPopup(
    'https://share.naver.com/web/shareView.nhn?url=' + encodeURIComponent(url) + '&title=' + encodeURIComponent(title),
    720,
    720
  );
}

export function shareFacebook(url) {
  openPopup('https://www.facebook.com/sharer/sharer.php?u=' + encodeURIComponent(url), 720, 350);
}

export function shareTwitter(title, url) {
  openPopup(
    'https://twitter.com/intent/tweet?text=' + encodeURIComponent(title) + '&url=' + encodeURIComponent(url),
    720,
    350
  );
}

export function shareKakao(title, url, imageUrl) {
  const kakao = window.Kakao;
  if (kakao && kakao.isInitialized()) {
    kakao.Share.sendDefault({
      objectType: 'feed',
      content: {
        title,
        imageUrl,
        link: {
          mobileWebUrl: url,
          webUrl: url,
        },
      },
    });
  }
}
