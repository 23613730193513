import React from 'react';

export default function ChannelPostListPager({ page, setPage, totalPage }) {
  const gotoFirstPage = () => {
    setPage(1);
  };

  const gotoLastPage = () => {
    setPage(totalPage);
  };

  const gotoPrevPage = (currentPage) => {
    setPage(currentPage - 1 > 1 ? currentPage - 1 : 1);
  };

  const gotoNextPage = (currentPage) => {
    setPage(currentPage + 1 < totalPage ? currentPage + 1 : totalPage);
  };

  return (
    <div className="flex gap-2 justify-end items-center mt-4">
      <button className="btn btn-outline w-12" onClick={() => gotoFirstPage()} disabled={page === 1 || totalPage === 1}>
        <i className="fa-solid fa-angles-left"></i>
      </button>
      <button className="btn btn-outline w-12" onClick={() => gotoPrevPage(page)} disabled={page === 1}>
        <i className="fa-solid fa-angle-left"></i>
      </button>
      <button className="btn btn-outline w-12" onClick={() => gotoNextPage(page)} disabled={page === totalPage}>
        <i className="fa-solid fa-angle-right"></i>
      </button>
      <button
        className="btn btn-outline w-12"
        onClick={() => gotoLastPage()}
        disabled={page === totalPage || totalPage === 1}
      >
        <i className="fa-solid fa-angles-right"></i>
      </button>
      <span className="font-bold text-gray-600 text-sm ml-2">{`${page} of ${totalPage}`}</span>
    </div>
  );
}
