import { useTranslation } from 'react-i18next';

export const useLanguage = () => {
  const {
    i18n: { language },
  } = useTranslation();
  const isZHTW = language === 'zh-TW';
  const isKorean = language === 'ko';
  return { isZHTW, isKorean };
};
