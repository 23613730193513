import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useUnsubscribeEmail } from '../../hooks/useUnsubscribeEmail';
import './UnsubscribeEmail.css';

export default function UnsubscribeEmail() {
  const { t } = useTranslation();
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const email = searchParams.get('value');

  const { isPending, isValidEmail, resultEmail } = useUnsubscribeEmail(email);

  return (
    <div className="unsubscribe-page">
      <img src="/youtext-logo.png" width={158} alt="youtext" />
      <div className="message">
        {isValidEmail === false && (
          <p>{t('$유효하지 않은 이메일 주소', { email })}</p>
        )}
        {isValidEmail && isPending && (
          <p>{t('이메일 구독을 해지 중입니다.')}</p>
        )}
        {isValidEmail && !isPending && !resultEmail && (
          <p>
            {t(
              '오류가 발생해 해지하지 못 했습니다. 잠시 후 다시 시도해 주세요.'
            )}
          </p>
        )}
        {isValidEmail && !isPending && resultEmail && (
          <p>{t('$구독 해지 안내문', { resultEmail })}</p>
        )}
      </div>
      <p>
        {t('문의')}{' '}
        <a className="email-address" href="mailto:youtext@dable.io">
          youtext@dable.io
        </a>
      </p>
    </div>
  );
}
