import dayjs from 'dayjs';
import React, { useEffect, useMemo, useState } from 'react';
import DateRangePicker from '../../components/DateRangePicker';
import { useAuthContext } from '../../hooks/useAuthContext';
import { useTranslation } from 'react-i18next';
import RevenueContainer from '../../components/RevenueContainer';
import ChannelPostListContainer from '../../components/ChannelPostListContainer';
import { useChannelList } from '../../hooks/useChannelList';

class DashboardMode {
  static REVENUE = 'revenue';
  static POSTLIST = 'postlist';
}

export default function Dashboard() {
  const { t } = useTranslation();
  const { user } = useAuthContext();
  const { isAdmin, hasMultiChannels } = user;
  const yesterDay = dayjs().add(-1, 'day').toDate();
  const startDay = dayjs(yesterDay).add(-6, 'day').toDate();
  const [maxDate, setMaxDate] = useState(yesterDay);
  const [minDate, setMinDate] = useState(dayjs(yesterDay).add(-2, 'month').toDate());
  const [dateRange, setDateRange] = useState({
    startDate: startDay,
    endDate: yesterDay,
    key: 'selection',
  });
  const [channelList, setChannelList] = useState([]);
  const [selectedChannelUrl, setSelectedChannelUrl] = useState(null);
  const [selectedChannelIndex, setSelectedChannelIndex] = useState(-1);
  const [dashboardMode, setDashboardMode] = useState(DashboardMode.REVENUE);
  const { fetchChannelList } = useChannelList();
  const selectedYtChannelId = useMemo(() => {
    try {
      const url = new URL(selectedChannelUrl);
      return url.pathname.endsWith('/') ? url.pathname.slice(0, -1) : url.pathname;
    } catch {
      return '';
    }
  }, [selectedChannelUrl]);

  const adjustDatePickerRange = (startDate, endDate) => {
    const startDateString = dayjs(startDate).format('YYYYMMDD');
    const endDateString = dayjs(endDate).format('YYYYMMDD');
    if (startDateString === endDateString) {
      setMinDate(dayjs(startDate).add(-2, 'month').toDate());
      const after2M = dayjs(startDate).add(2, 'month').toDate();
      if (dayjs(after2M).isAfter(yesterDay, 'day')) {
        setMaxDate(yesterDay);
      } else {
        setMaxDate(after2M);
      }
    }
  };

  const handleSelectDateRange = (ranges) => {
    setDateRange(ranges.selection);
    const { startDate, endDate } = ranges.selection;
    adjustDatePickerRange(startDate, endDate);
    if (isAdmin && selectedChannelIndex > -1) {
      setSelectedChannelUrl(channelList[selectedChannelIndex].channelUrl);
    } else if (user && user.channelUrl) {
      setSelectedChannelUrl(user.channelUrl);
    }
  };

  const handleSelectChannel = (event) => {
    setSelectedChannelIndex(event.target.value);
  };

  const handleDashboardMode = (value) => {
    setDashboardMode(value);
  };

  useEffect(() => {
    const loadAllChannelListForAdmin = async () => {
      const channelList = await fetchChannelList();
      setChannelList(channelList);
    };

    const loadAllChannelListForUser = async (email) => {
      const channelList = await fetchChannelList(email);
      setChannelList(channelList);
    };

    if (user) {
      if (user.isAdmin) {
        loadAllChannelListForAdmin();
      } else if (user.hasMultiChannels) {
        loadAllChannelListForUser(user.email);
      } else if (user.channelUrl) {
        setSelectedChannelUrl(user.channelUrl);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (selectedChannelIndex === -1) {
      return;
    }
    const channel = channelList[selectedChannelIndex];
    setSelectedChannelUrl(channel.channelUrl);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedChannelIndex, channelList]);

  return (
    <div className="p-5 mx-auto" style={{ minWidth: '1000px', maxWidth: '1400px' }}>
      <div className="flex items-center">
        <div className="mr-7">
          {(isAdmin || hasMultiChannels) && (
            <select
              disabled={channelList.length === 0}
              className="select select-bordered border-gray-800"
              value={selectedChannelIndex}
              onChange={(e) => handleSelectChannel(e)}>
              <option disabled value={-1}>
                {t('채널을 선택해 주세요')}
              </option>
              {channelList.map((it, index) => (
                <option key={it.channelUrl} value={index}>
                  {it.channelName}
                </option>
              ))}
            </select>
          )}
          {!isAdmin && !hasMultiChannels && (
            <a href={user.channelUrl} target="_blank" rel="noreferrer" className="badge badge-primary badge-lg">
              {user.channelName}
            </a>
          )}
        </div>
        <div className="mr-auto flex gap-1">
          <button
            className={`btn ${dashboardMode === DashboardMode.REVENUE ? 'btn-square' : 'btn-outline'}`}
            style={{ width: '100px' }}
            onClick={(e) => {
              handleDashboardMode(DashboardMode.REVENUE);
            }}>
            {t('날짜')}
          </button>
          <button
            className={`btn ${dashboardMode === DashboardMode.POSTLIST ? 'btn-square' : 'btn-outline'}`}
            style={{ width: '100px' }}
            onClick={(e) => {
              handleDashboardMode(DashboardMode.POSTLIST);
            }}>
            {t('콘텐츠')}
          </button>
        </div>
        {dashboardMode === DashboardMode.REVENUE ? (
          <div className="ml-7">
            <DateRangePicker
              dateRange={dateRange}
              minDate={minDate}
              maxDate={maxDate}
              handleSelectDateRange={handleSelectDateRange}
            />
          </div>
        ) : null}
      </div>
      {selectedYtChannelId && selectedYtChannelId.length > 0 && (
        <div className="mt-10 w-full">
          {dashboardMode === DashboardMode.REVENUE ? (
            <RevenueContainer ytChannelId={selectedYtChannelId} dateRange={dateRange} />
          ) : (
            <ChannelPostListContainer ytChannelId={selectedYtChannelId} />
          )}
        </div>
      )}
    </div>
  );
}
