import { createContext, useReducer, useEffect } from 'react';
import { projectAuth, projectFirestore } from '../firebase';

export const AuthContext = createContext();

export const authReducer = (state, action) => {
  switch (action.type) {
    case 'LOGIN':
      return { ...state, user: action.payload };
    case 'LOGOUT':
      return { ...state, user: null };
    case 'AUTH_IS_READY':
      return { user: action.payload, authIsReady: true };
    default:
      return state;
  }
};

export const AuthContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(authReducer, {
    user: null,
    authIsReady: false,
  });

  useEffect(() => {
    const signout = async () => {
      await projectAuth.signOut();
      dispatch({ type: 'LOGOUT' });
    };

    const unsub = projectAuth.onAuthStateChanged(async (user) => {
      if (user && user.uid) {
        const signedUser = await projectFirestore.collection('users').doc(user.uid).get();
        if (!signedUser.exists) {
          // 개발 테스트 또는 임의로 사용자를 DB에서 삭제할 경우
          await signout();
          return;
        }

        const { channelId, channelName, channelUrl, isAdmin, hasMultiChannels, withdrawal } = signedUser.data();
        if (!channelId || !channelName || !channelUrl) {
          // channelId 와 같이 신규 필수 필드 추가시 없는 경우, 재접속시 구글 로그인을 다시하게 하여 DB에 사용자 정보를 넣게 한다.
          await signout();
        } else if (withdrawal && withdrawal === true) {
          // 현재 로그인하 사용자가 탈퇴되어 있는 사용자이면 다음 번 접속시나 새로고침시에 로그아웃한다.
          await signout();
        } else {
          dispatch({
            type: 'AUTH_IS_READY',
            payload: {
              ...user,
              channelUrl,
              channelName,
              isAdmin,
              hasMultiChannels,
            },
          });
        }
      } else {
        dispatch({
          type: 'AUTH_IS_READY',
          payload: user,
        });
      }
      unsub();
    });
  }, []);

  return <AuthContext.Provider value={{ ...state, dispatch }}>{children}</AuthContext.Provider>;
};
