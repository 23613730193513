import { secureAxios } from '../utils/secureAxios';
import dayjs from 'dayjs';
import { useState } from 'react';
import { dateRangeOverlaps } from '../utils/dateRange';
import EndPoint from '../utils/endpoint';
import { formatCurrency, numberWithCommas } from '../utils/formatNumber';

// 주소 창에 ?__custom_currency=twd 형식의 쿼리를 입력해서 강제로 통화를 변경할 수 있습니다.
let userCustomCurrency = null;
if (window && window.location && window.location.search) {
  const searchParams = new URLSearchParams(window.location.search);
  userCustomCurrency = searchParams.get('__custom_currency');
}

export const useRevenue = () => {
  const [isPending, setIsPending] = useState(false);
  const [error, setError] = useState(null);

  const [pageView, setPageView] = useState(0);
  const [revenue, setRevenue] = useState(0);
  const [expectAdRevenue, setExpectAdRevenue] = useState(0);
  const [promotion, setPromotion] = useState(0);
  const [hasPromotion, setHasPromotion] = useState(false);
  const [rpm, setRPM] = useState(0);

  const [history, setHistory] = useState([]);

  const checkIfHasPromotion = (history, startDate, endDate) => {
    if (history.length === 0) {
      return false;
    }

    const validHistory = history.filter((it) => it.mg_promo_start_date && it.mg_promo_end_date);
    if (validHistory.length === 0) {
      return false;
    }

    return validHistory
      .map((it) => {
        return {
          mg_promo_start_date: dayjs(it.mg_promo_start_date).toDate(),
          mg_promo_end_date: dayjs(it.mg_promo_end_date).toDate(),
        };
      })
      .some((it) => dateRangeOverlaps(startDate, endDate, it.mg_promo_start_date, it.mg_promo_end_date));
  };

  const fetchRevenue = async (ytChannelId, startDate, endDate) => {
    setIsPending(true);
    setError(null);
    setPageView(0);
    setRevenue(0);
    setExpectAdRevenue(0);
    setPromotion(0);
    setHasPromotion(0);
    setRPM(0);
    try {
      const startDateString = dayjs(startDate).format('YYYY-MM-DD').toString();
      const endDateString = dayjs(endDate).format('YYYY-MM-DD').toString();
      const response = await secureAxios.get(EndPoint.revenue(), {
        params: {
          channel_id: ytChannelId,
          start_date: startDateString,
          end_date: endDateString,
          custom_currency: userCustomCurrency,
        },
      });
      const { pv, currency, total_revenue, ad_pub_revenue, mg_promo_revenue, ad_rpm, revenue_list } =
        response.data.result;
      const reversedHistory = revenue_list && revenue_list.length > 0 ? revenue_list.reverse() : [];
      const historyWithCurrency = reversedHistory.map((it) => ({ ...it, currency }));
      setPageView(numberWithCommas(pv ?? 0));
      setRevenue(formatCurrency(currency, total_revenue ?? 0));
      setExpectAdRevenue(formatCurrency(currency, ad_pub_revenue ?? 0));
      setPromotion(formatCurrency(currency, mg_promo_revenue ?? 0));
      setHasPromotion(checkIfHasPromotion(historyWithCurrency, startDate, endDate));
      setRPM(formatCurrency(currency, ad_rpm ?? 0));
      setHistory(historyWithCurrency);
      setIsPending(false);
    } catch (error) {
      setIsPending(false);
      setError(error.message);
      setPageView(0);
      setRevenue(0);
      setExpectAdRevenue(0);
      setPromotion(0);
      setHasPromotion(false);
      setRPM(0);
    }
  };

  return {
    fetchRevenue,
    pageView,
    revenue,
    expectAdRevenue,
    hasPromotion,
    promotion,
    rpm,
    history,
    isPending,
    error,
  };
};
