type Country = 'kr' | 'tw' | 'my' | 'id' | 'feed' | 'newsa_kr' | undefined;

/**
 * 카카오톡 공유하기에는 shortUrl이 동작하지 않아, utm 태그가 붙은 원본 URL을 생성합니다.
 */
export function makeYouTextShareUrl(country: Country, slug: string): string {
  let domain: string;
  switch ((country || '').toLowerCase()) {
    case 'tw':
      domain = 'tw.youtext.ai';
      break;
    case 'my':
      domain = 'my.youtext.ai';
      break;
    case 'id':
      domain = 'id.youtext.ai';
      break;
    case 'feed':
    case 'newsa_kr': // TODO: 추후 제거
      domain = 'feed.youtext.ai';
      break;
    default:
      domain = 'youtext.ai';
  }
  const utmParams = 'utm_source=admin&utm_medium=yshare&utm_campaign=yshare';
  return `https://${domain}/${slug}?${utmParams}`;
}

export function makeYouTextShareShortUrl(country: Country, slug: string): string {
  switch ((country || '').toLowerCase()) {
    case 'tw':
      return 'https://go.youtext.com/yt/' + slug;
    case 'my':
      return 'https://go.youtext.com/ym/' + slug;
    case 'id':
      return 'https://go.youtext.com/yi/' + slug;
    case 'feed':
    case 'newsa_kr': // TODO: 추후 제거
      return 'https://go.youtext.com/yf/' + slug;
    default:
      return 'https://go.youtext.com/y/' + slug;
  }
}
