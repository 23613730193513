import React from 'react';
import './PromotionStat.css';

export default function PromotionStat({ totalRevenueTitle, totalRevenueValue, expectAdRevenueTitle, expectAdRevenueValue, hasPromotion, promotionTitle, promotionValue,  descriptions, isLoading }) {
  return (
    <div className="w-full">
      <div className="dropdown dropdown-hover w-full">
        <div tabIndex="0" className="shadow stats w-full">
          { hasPromotion ? (
            <div className="stat bg-secondary text-white">
              <div className="stat-title">{totalRevenueTitle}</div>
              {isLoading ? (
                <div className="stat-value text-center">
                  <span className="btn btn-ghost loading"></span>
                </div>
              ) : (
                <div className="stat-value">{totalRevenueValue}</div>
              )}
            </div>
          ) : null}
          { hasPromotion ? (
            <div className="stat bg-secondary text-white hide-left-border">
              <div className="stat-title">{promotionTitle}</div>
              {isLoading ? (
                <div className="stat-value text-center">
                  <span className="btn btn-ghost loading"></span>
                </div>
              ) : (
                <div className="stat-value">{promotionValue}</div>
              )}
            </div>
          ) : null}
          <div className="stat bg-secondary text-white hide-left-border">
            <div className="stat-title">{expectAdRevenueTitle}</div>
            {isLoading ? (
              <div className="stat-value text-center">
                <span className="btn btn-ghost loading"></span>
              </div>
            ) : (
              <div className="stat-value">{expectAdRevenueValue}</div>
            )}
          </div>
        </div>
        <div tabIndex="0" className="dropdown-content p-4 shadow-md rounded-xl flex flex-col gap-3 bg-white">
            {
              descriptions.filter((it) => it.show).map((desc, index) => (
                <div key={index}>
                  <h2 className="font-bold text-xl">{desc.title}</h2>
                  <p className="text-sm">{desc.body}</p>
                </div>
              ))
            }
        </div>
      </div>
    </div>
  )
}
