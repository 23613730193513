import googleSheet from '../config/googlesheet.json';
import { GoogleSpreadsheet } from 'google-spreadsheet';

export const loadGoogleSheet = async (sheetID, emailColumn, channelUrlColumn, channelNameColumn, withdrawalColumn) => {
  const doc = new GoogleSpreadsheet(sheetID);
  doc.useServiceAccountAuth({
    client_email: googleSheet.client_email,
    private_key: googleSheet.private_key,
  });
  await doc.loadInfo();
  const sheet = doc.sheetsByIndex[0];
  await sheet.loadCells();
  const result = [];
  for (let i = 2; i <= sheet.rowCount; i += 1) {
    try {
      const email = sheet.getCellByA1(`${emailColumn}${i}`);
      const channelUrl = sheet.getCellByA1(`${channelUrlColumn}${i}`);
      const channelName = sheet.getCellByA1(`${channelNameColumn}${i}`);
      const withdrawal = sheet.getCellByA1(`${withdrawalColumn}${i}`);
      const channelNameValue = channelName.value ? channelName.value : '채널 이름 없음';
      const withdrawalValue = withdrawal.value ? ['탈퇴', '탈퇴 완료', '탈퇴완료'].includes(withdrawal.value) : false;
      if (email.value !== null && channelUrl.value !== null) {
        result.push({
          channelId: new URL(channelUrl.value).pathname,
          emailList: email.value.split(',').map((it) => it.trim()),
          channelUrl: channelUrl.value,
          channelName: channelNameValue,
          isWithdrawn: withdrawalValue,
        });
      }
    } catch (error) {
      continue;
    }
  }
  return result;
};

export const loadAdminListSheet = async () => {
  return await loadGoogleSheet('1tqw_rDn3SfrN3-5tvfGPEt14b3PT1hwcTKK1Bhn8agE', 'B', 'C', 'E', 'F');
};
