import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import { useAuthContext } from './hooks/useAuthContext';
import './App.css';
import Dashboard from './pages/dashboard/Dashboard';
import Login from './pages/login/Login';
import Navbar from './components/Navbar';
import UnsubscribeEmail from './pages/unsubscribeEmail/UnsubscribeEmail';

function App() {
  const { user, authIsReady } = useAuthContext();
  const isUnsubscribePathName = window.location.pathname.toLowerCase().startsWith('/unsubscribe/email');

  return (
    <div>
      {authIsReady && (
        <BrowserRouter>
          {!isUnsubscribePathName && user && <Navbar />}
          <Switch>
            <Route exact path="/">
              {!user ? <Login /> : <Redirect to="/dashboard" />}
            </Route>
            <Route path="/dashboard">{user ? <Dashboard /> : <Redirect to="/" />}</Route>
            <Route path="/unsubscribe/email">
              <UnsubscribeEmail />
            </Route>
          </Switch>
        </BrowserRouter>
      )}
    </div>
  );
}

export default App;
