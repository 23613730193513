import React from 'react';

export default function Stat({ title, value, description, isLoading }) {
  return (
    <div className="w-full">
      <div className="dropdown dropdown-hover w-full">
        <div tabIndex="0" className="shadow stats w-full">
          <div className="stat bg-secondary text-white">
            <div className="stat-title">{title}</div>
            {isLoading ? (
              <div className="stat-value text-center">
                <span className="btn btn-ghost loading"></span>
              </div>
            ) : (
              <div className="stat-value">{value}</div>
            )}
          </div>
        </div>
        {description && (
          <div tabIndex="0" className="dropdown-content p-4 shadow-md rounded-xl flex flex-col gap-3 bg-white">
            <h2 className="font-bold text-xl">{description.title}</h2>
            <p className="text-sm">{description.body}</p>
          </div>
        )}
      </div>
    </div>
  );
}
