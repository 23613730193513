import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import translationEn from './locales/en/translation.json';
import translationKo from './locales/ko/translation.json';
import translationZhTw from './locales/zh-TW/translation.json';

const resources = {
  en: {
    translation: translationEn,
  },
  ko: {
    translation: translationKo,
  },
  'zh-TW': {
    translation: translationZhTw,
  },
};

let lang = 'ko';
const savedLang = localStorage.getItem('i18n-lang');
if (savedLang) {
  try {
    lang = JSON.parse(savedLang);
  } catch {
    lang = 'ko';
  }
}
i18n.use(initReactI18next).init({
  resources,
  lng: lang,
  keySeparator: false,
  interpolation: {
    escapeValue: false,
  },
});
