import classnames from 'classnames';

const ArrowUp = ({ className }) => (
  <svg
    className={classnames('w-4 h-4', className)}
    fill="none"
    stroke="currentColor"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg">
    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 10l7-7m0 0l7 7m-7-7v18"></path>
  </svg>
);

export default ArrowUp;
