import React, { useEffect } from 'react';
import { useLocalStorage } from '@penseapp/uselocalstorage';
import { useTranslation } from 'react-i18next';
import './Languages.css';

function Segment({ title, selected, handleClick }) {
  return (
    <div onClick={handleClick} className={selected ? 'segment selected' : 'segment'}>
      {title}
    </div>
  );
}

export default function Languages() {
  const { i18n } = useTranslation();
  const [i18nLang, setI18nLang] = useLocalStorage('i18n-lang', 'ko', false);

  useEffect(() => {
    i18n.changeLanguage(i18nLang);
  }, [i18n, i18nLang]);

  return (
    <div className="languages-panel">
      <Segment title="한국어" selected={i18nLang === 'ko'} handleClick={() => setI18nLang('ko')} />
      <Segment
        title="中文(繁體)"
        selected={i18nLang === 'zh-TW'}
        handleClick={() => setI18nLang('zh-TW')}
      />
      <Segment title="English" selected={i18nLang === 'en'} handleClick={() => setI18nLang('en')} />
    </div>
  );
}
