import React from 'react';
import { useTranslation } from 'react-i18next';
import { formatCurrency, numberWithCommas } from '../utils/formatNumber';
import PromotionCell from './PromotionCell';
import './RevenueTable.css';

export default function RevenueTable({ history, hasPromotion }) {
  const { t } = useTranslation();
  return (
    <table className="revenue-table w-full">
      {hasPromotion ? (
        <colgroup>
          <col style={{ width: '16.7%' }} />
          <col style={{ width: '16.7%' }} />
          <col style={{ width: '16.7%' }} />
          <col style={{ width: '16.7%' }} />
          <col style={{ width: '16.7%' }} />
          <col style={{ width: '16.7%' }} />
        </colgroup>
      ) : (
        <colgroup>
          <col style={{ width: '25%' }} />
          <col style={{ width: '25%' }} />
          <col style={{ width: '25%' }} />
          <col style={{ width: '25%' }} />
        </colgroup>
      )}
      <thead>
        <tr>
          <th className="text-center">{t('날짜')}</th>
          {hasPromotion ? (
            <>
              <th className="text-right">{t('예상 수입 합계')}</th>
              <th className="text-right">
                <span className="pr-3">{t('프로모션 수입')}</span>
              </th>
            </>
          ) : null}
          <th className="text-right">{t('예상 광고 수입')}</th>
          <th className="text-right">{t('페이지뷰')}</th>
          <th className="text-right">RPM</th>
        </tr>
      </thead>
      <tbody>
        {history.length === 0 && (
          <tr>
            <td colSpan={4} className="text-center">
              {t('정산 내역이 없습니다.')}
            </td>
          </tr>
        )}
        {history.map((it) => {
          return (
            <tr key={it.date}>
              <td className="text-center">{it.date}</td>
              {hasPromotion ? (
                <>
                  <td className="text-right">{t('$가격', { price: formatCurrency(it.currency, it.total_revenue) })}</td>
                  <td className="text-right">
                    <PromotionCell promotionItem={it} />
                  </td>
                </>
              ) : null}
              <td className="text-right">
                {t('$가격', {
                  price: formatCurrency(it.currency, it.ad_pub_revenue),
                })}
              </td>
              <td className="text-right">{numberWithCommas(it.pv)}</td>
              <td className="text-right">{t('$가격', { price: formatCurrency(it.currency, it.ad_rpm) })}</td>
              <td></td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}
